import { clamp } from '@antfu/utils'
import { AudioPeaks } from 'vocal-recorder'

interface PeaksRenderProps {
  peaks: number[]
  rect: {
    width: number
    height: number
  }

  animate?: boolean

  barColor?: string

  /** Width of rect bar */
  barWidth?: number
}

export function getPeaksSVG(props: PeaksRenderProps) {
  const { animate = false, barWidth = 1, barColor = 'currentColor' } = props
  const { height, width } = props.rect

  // Get peaks within range
  const peaks = Array.from(
    new AudioPeaks(props.peaks, clamp(width / 4, 30, 100), barWidth, height - 2)
  )

  // Determine gap
  const totalGap = (peaks.length - 1) * barWidth
  const totalSpace = width - totalGap
  const gap = totalSpace / (peaks.length)

  const rects = peaks.map((h, index) => {
    h ||= barWidth

    const rx = barWidth / 2
    const y = (height - h) / 2 || barWidth
    const x = index * (gap + barWidth)

    // Apply style if animating
    const style = animate ? `style="--delay: ${index * 10}ms"` : ''

    return `<rect rx="${rx}" x="${x}" y="${y}" width="${barWidth}" height="${h}" ${style} />`
  })

  const animationCode = `
    <style>
      rect {
        opacity: 0;
        transform-origin: center;
        animation: peaks-appear 800ms ease forwards;
        animation-delay: var(--delay);
      }

      @keyframes peaks-appear {
        from { transform: scaleY(0) translateY(50%); }
        to {  opacity: 1; }
      }
    </style>
  `

  const code = `
    <svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">
      ${animate ? animationCode : ''}

      <g fill="${barColor}" shape-rendering="geometricPrecision">
        ${rects.join('\n')}
      </g>
    </svg>
  `

  // Set mask images
  return {
    /** RAW SVG code */
    code,

    peaks,
    rects,

    getBlob() {
      return new Blob([code], { type: 'image/svg+xml' })
    },

    /** Returns data url of image. */
    getDataURL(base64 = false) {
      return base64
        ? `url(data:image/svg+xml;base64,${globalThis.btoa(code)})` as const
        : `url(data:image/svg+xml,${encodeURIComponent(code)})` as const
    }
  }
}
